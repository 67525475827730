import './Project.css'
import { MdLocationPin, MdDateRange } from 'react-icons/md'
import { FaLocationDot } from 'react-icons/fa6';
import { RxDimensions } from 'react-icons/rx'
import { BsFillPersonFill } from 'react-icons/bs'
import { useState, useEffect } from 'react';
import { Swiper as SwiperParent, SwiperSlide, useSwiper } from 'swiper/react';


const Specification = (props) => {

  if (props.LangAlignment.textAlign == 'right') {
    return (
      <div style={props.LangAlignment}>
        <p style={props.LangAlignment}>{props.value}</p>
        {props.icon}
      </div>
    )
  } else {
    return (
      <div style={props.LangAlignment}>
        {props.icon}
        <p style={props.LangAlignment}>{props.value}</p>
      </div>
    )
  }

}

const SlideNext = () => {

  const Swiper = useSwiper();

  useEffect(() => {
    setInterval(() => {
      Swiper.slideNext(1000);
    }, 2000)
  }, []);

  return <div></div>

}

const Project = (props) => {
  const [Hovered, setHover] = useState(false);

  function handleHover() {
    setHover(true);
  }
  function handleNOTHover() {
    setHover(false);
  }

  return (
    <div onMouseOut={handleNOTHover} onMouseOver={handleHover} className="project">
      <div className="image-container">
        {
          props.Images ? <div style={{height: "400px", display: "relative", maxWidth: "710px"}}>
            <SwiperParent style={{ height: "400px", width: "100%" }} pagination={true} loop={true} className="mySwiper">
              {
                props.Images.map(img => <SwiperSlide style={{ objectFit: "contain", width: "100%", height: "100%", display: "flex" }}><img className={Hovered ? 'img-hvr' : ''} src={img} /></SwiperSlide>)
              }
              <SlideNext />
            </SwiperParent>
          </div>
            : <img className={Hovered ? 'img-hvr' : ''} src={props.source} />
        }
        <div className={Hovered ? 'project-context visible ' : 'project-context '}>
          <div className="inside-project-context">
            <h3>{props.title}</h3>
            <div style={props.LangAlignment} className="project__specifications">
              <Specification LangAlignment={props.LangAlignment} icon={<FaLocationDot />} value={props.location} />
              {/* <Specification LangAlignment={props.LangAlignment} icon={<BsFillPersonFill />} value={props.by} /> */}
              {/* <Specification LangAlignment={props.LangAlignment} icon={<RxDimensions />} value={props.scope} /> */}
              {/* <Specification LangAlignment={props.LangAlignment} icon={<MdDateRange />} value={props.date} /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="text-container">
            <h1>{props.title}</h1>
            <div className="project__specifications">
            <Specification icon={<FaLocationDot />} value={props.location} />
            <Specification icon={<RxDimensions />} value={props.scope} />
            <Specification icon={<MdDateRange />} value={props.date} />
            </div>
        </div> */}
    </div>
  )
}

export default Project
